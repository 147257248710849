import { useCallback, useEffect, useRef } from 'react';

function useOutsideClick(ref, callback, ignoreRefs = []) {
  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  const handleClickOutside = useCallback(
    event => {
      // Return early if the target element or any ignored element contains the click
      if (ref.current?.contains(event.target)) return;

      if (ignoreRefs.length > 0) {
        for (const ignoreRef of ignoreRefs) {
          if (ignoreRef.current?.contains(event.target)) {
            return;
          }
        }
      }

      // Execute the callback if the click is outside
      callbackRef.current();
    },
    [ref, ignoreRefs]
  );

  // Add and clean up the event listener for detecting outside clicks
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  return null; // This hook doesn't return anything
}

export default useOutsideClick;
