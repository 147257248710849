import NoFoundIcon from '#/assets/svgs/NoFoundIcon.svg';
import styles from './NotFoundScreen.module.scss';

function NotFoundScreen({ title = 'Data', description }) {
  return (
    <div className={`${styles.notFoundScreen} not-found-screen`}>
      <NoFoundIcon />
      <h4>No {title} Found</h4>
      {!!description && <p>{description}</p>}
    </div>
  );
}

export default NotFoundScreen;
