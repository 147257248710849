export const apiEndpoints = {
  GOOGLE_LOGIN: '/users/google/login/',
  PROFILE: '/users/me/',
  SUBSCRIPTION_PLANS: '/subscription/dashboard/',
  PLAN_CHECKOUT: '/subscription/checkout-session/',
  UPLOAD_FILE: '/chatbot/upload-file/',
  GET_CHARACTER_LIMIT: '/subscription/characters-limit/',
  GET_CHATBOT: '/chatbot/',
  EXTRACT_WEBSITE_LINKS: '/chatbot/initiate-url-fetching/',
  CHATBOT_DETAIL: id => `/chatbot/${id}/`,
  UPLOAD_CHAT_BOT_IMAGE: '/chatbot/image/',
  LEAD_LIST: id => `/chatbot/${id}/lead/list/`,
  LEAD_DELETE: leadId => `/chatbot/lead/delete/${leadId}/`,
  CREATE_LEAD: '/chatbot/create-lead/',
  LEAD_NAME: id => `/chatbot/${id}/leads-name/list/`,
  UPDATE_CHATBOT: id => `/chatbot/${id}/update/`,
  ADD_SITE_URL: '/chatbot/add-site-url/',
  CHATBOT_TOGGLE_STATUS: id => `/chatbot/toggle-status/${id}/`,
  GET_STICKY_BUTTON: '/chatbot/sticky-button/get/',
  CREATE_STICKY_BUTTON: '/chatbot/sticky-button/create/',
  UPDATE_STICKY_BUTTON: id => `/chatbot/sticky-button/update/${id}/`,
  DELETE_STICKY_BUTTON: id => `/chatbot/sticky-button/${id}/`,
  STICKY_BUTTON_TOGGLE_STATUS: id => `/chatbot/sticky-button/toggle-status/${id}/`,
  LEAD_RESPONSE: id => `/chatbot/lead-record/get/${id}/`,
  CHATBOT_LEAD_LIST: id => `/chatbot/${id}/lead/list/`,
  REACTIONS: '/reactions/',
  FETCH_PROGRESS_URLS: taskId => `/chatbot/url-fetching-progress/${taskId}/`,
  FETCH_URLS: taskId => `/chatbot/fetch-urls/${taskId}/`,
  NOTIFICATIONS: '/notifications/',
  USER_DASHBOARD: '/users/dashboard/',
  ADMIN_DASHBOARD: '/users/admin-dashboard/',
  USER_LEADS: '/chatbot/user-lead/',
  USERS_LIST: '/users/list/',
  ADMIN_CONVERSATIONS: '/chatbot/conversation/admin-list/',
  USERS_DETAIL: id => `/users/detail/${id}/`,
  USER_CHATBOT_DETAIL: id => `/chatbot/user/${id}/`,
  ADMIN_LEAD_LIST: id => `/chatbot/lead-record/admin/${id}/`,
  GET_USER_STICKY_BTN: id => `/chatbot/sticky-button/get/user/${id}/`,
  MEMBERSHIP_PLANS: '/subscription/',
  SUBSCRIPTION_HISTORY: id => `/users/${id}/subscription-history/`,
  USERS_ALL_LEADS: '/chatbot/leads-name/list/',
  ADMIN_CHATBOT: id => `/chatbot/admin-details/${id}/`,
  // CHATBOT_DETAILS: id => `${import.meta.env.VITE_FRONTEND_BASE_URL}/chat-bot.js?id=${id}`,
  UPGRADE_SUBSCRIPTION: id => `/subscription/${id}/update/`,
  ADD_SCRAPPED_URL: '/chatbot/add-scraped-url/',
  CANCEL_SUBSCRIPTION: id => `/subscription/${id}/cancel/`,
};
