import { createContext, useContext, useEffect, useState } from 'react';

import { apiEndpoints, queryKeys, useGetQuery } from '#/services';
import { ACCESS_TOKEN, NOTIFICATION_REFETCH_INTERVAL } from '#/utils/constants';
import { getLocalStorageItem } from '#/utils/localStorage';

export const AppContext = createContext();

export const useAppContext = () => useContext(AppContext);

export const AppContextProvider = ({ children }) => {
  const accessToken = getLocalStorageItem(ACCESS_TOKEN);

  const [userDetails, setUserDetails] = useState({});
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isResponsive, setIsResponsive] = useState(false);

  const { data, isPending: userDetailLoading } = useGetQuery({
    key: queryKeys.USER_DETAILS,
    url: apiEndpoints.PROFILE,
    options: {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!accessToken,
      refetchInterval: NOTIFICATION_REFETCH_INTERVAL,
    },
  });

  const { data: characterLimit } = useGetQuery({
    key: queryKeys.CHARACTER_LIMIT,
    url: apiEndpoints.GET_CHARACTER_LIMIT,
    options: {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!userDetails?.isSubscriptionPurchased && !userDetails?.isStaff,
    },
  });

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const windowSizeHandler = () => {
    setIsResponsive(window.innerWidth < 576);
  };

  useEffect(() => {
    window.addEventListener('resize', windowSizeHandler);
    windowSizeHandler();

    return () => window.removeEventListener('resize', windowSizeHandler);
  }, []);

  useEffect(() => {
    if (!!data?.data) {
      const { data: userData } = data;
      setUserDetails(userData);
    }
  }, [data]);

  const contextValue = {
    accessToken,
    userDetails,
    userDetailLoading,
    characterLimit,
    sidebarOpen,
    setSidebarOpen,
    toggleSidebar,
    isResponsive,
  };

  return <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>;
};
