export const queryKeys = {
  USER_DETAILS: 'user-details',
  SUBSCRIPTION_DETAILS: 'subscription-details',
  SUBSCRIBE_PLAN: 'subscribe-plan',
  CHARACTER_LIMIT: 'character-limit',
  URL_FETCHING_PROGRESS: 'url-fetching-progress',
  FETCH_URLS: 'fetch-urls',
  CHATBOT_LIST: 'chatbot-list',
  CHATBOT_DETAIL: 'chatbot-detail',
  LEAD_LIST: 'leads-list',
  LEAD_NAME: 'lead-name',
  LEAD_RESPONSE: 'lead-response',
  LEAD_LIST: 'lead-list',
  TOGGLE_STATUS: 'toggle-status',
  STICKY_BUTTON_LIST: 'sticky-button-list',
  STICKY_BTN_DETAIL: 'sticky-btn-detail',
  REACTIONS: 'reactions',
  NOTIFICATIONS: 'notifications',
  DASHBOARD: 'dashboard',
  ADMIN_DASHBOARD: 'admin-dashboard',
  CHATBOT_USERS: 'chatbot-users',
  USERS_CONVERSATION: 'users-conversation',
  USERS: 'users',
  USER_DETAIL: 'user-detail',
  USER_CHATBOT: 'user-chatbot',
  USER_STICKY_BTN: 'user-sticky-btn',
  SUBSCRIBE_PLAN: 'subscribe-plan',
  SUBSCRIPTION_HISTORY: 'subscription-history',
  ALL_LEAD_NAME: 'all-lead-name',
  ADMIN_CHATBOT_DETAIL: 'admin-chatbot-detail',
};
