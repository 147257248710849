export const ROUTES = {
  LAYOUT: '/',
  LOGIN: '/login',
  DASHBOARD: '/dashboard',
  MY_PROFILE: '/my-profile',
  MEMBERSHIP: '/membership',
  MY_CHATBOT: '/my-chatbots',
  CHATBOT_DETAIL: '/my-chatbots/:id',
  CHATBOT_CREATE: '/my-chatbots/create-chatbot/:id',
  STICKY_SIDE_BUTTON: '/sticky-side-button',
  STICKY_SIDE_BUTTON_CREATE: '/sticky-side-button/create/:id?',
  STICKY_SIDE_BUTTON_DETAIL: '/sticky-side-button/detail/:id',
  DASHBOARD: '/dashboard',
  USER_MANAGEMENT: '/user-management',
  USER_MANAGEMENT_DETAIL: '/user-management/:id',
  PAGE_NOT_FOUND: '/page-not-found',
};
