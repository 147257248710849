import * as Yup from 'yup';

export const WebsiteUrlSchema = Yup.object().shape({
  websiteUrl: Yup.string().required().label(),
});

export const basicInformationSchema = Yup.object({
  projectName: Yup.string().required('Project name is required'),
  domain: Yup.string().required('Domain is required'),
  supportEmail: Yup.string().email('Invalid email address').required('Support email is required'),
  whatsappAdded: Yup.boolean(),
  whatsappNumber: Yup.string().when('whatsappAdded', (whatsappAdded, schema) =>
    whatsappAdded[0]
      ? schema
        .required('WhatsApp number is required')
        .matches(/^\+?[0-9]+$/, 'WhatsApp number must be digits only')
        .min(10, 'WhatsApp number must be at least 10 digits')
        .max(15, 'WhatsApp number cannot be more than 15 digits')
      : schema.notRequired()
  ),
});

export const appearanceSchema = Yup.object({
  botName: Yup.string().max(24, 'Chatbot name should not exceed 24 characters').required('Chatbot name is required'),
  welcomeMessage: Yup.string().required('Welcome message is required'),
  onlineStatus: Yup.string()
    .max(24, 'Chatbot name should not exceed 24 characters')
    .required('Online status is required'),
  chatbotImage: Yup.object().required('Image is required'),
});

export const leadMagnetSchema = Yup.object().shape({
  leadForm: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required('Name is required'),

      fields: Yup.array().of(
        Yup.object().shape({
          label: Yup.string().required('Label is required'),
        })
      ),
    })
  ),
});

export const callToActionSchema = Yup.object().shape({
  faqs: Yup.array().of(
    Yup.object().shape({
      question: Yup.string().required('Question is required'),
      answer: Yup.string().required('Answer is required'),
    })
  ),
});

export const stickyBtnBasicInfoSchema = Yup.object({
  buttonText: Yup.string().required('Button text is required'),
  leadForm: Yup.object().shape({
    name: Yup.string().required('Name is required'),
    fields: Yup.array().of(
      Yup.object().shape({
        label: Yup.string().required('Label is required'),
      })
    ),
  }),
});
