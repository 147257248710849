import { Suspense, lazy } from 'react';

import { Navigate, Route, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';

import { ChatbotLoader } from '#/common/components';
import { PrivateRoute, ROUTES } from '#/routes';

const Layout = lazy(() => import('#/common/components/Layout'));
const Login = lazy(() => import('#/pages/login/components/Login'));
const Membership = lazy(() => import('#/pages/membership/components/Membership'));
const MyChatBot = lazy(() => import('#/pages/my-chatbot/components/MyChatbot'));
const ChatbotDetail = lazy(() => import('#/pages/chatbot-detail/components/ChatbotDetail'));
const CreateChatbot = lazy(() => import('#/pages/chatbot-customization/components/CreateChatbot'));
const StickyButton = lazy(() => import('#/pages/sticky-button/components/StickyButton'));
const CreateStickyButton = lazy(() => import('#/pages/sticky-button/components/CreateStickyButton'));
const StickyButtonDetail = lazy(() => import('#/pages/sticky-button/components/StickyButtonDetail'));
const Dashboard = lazy(() => import('#/pages/dashboard/components/Dashboard'));
const UserManagement = lazy(() => import('#/pages/user-management/components/UserManagement'));
const UserDetail = lazy(() => import('#/pages/user-management/components/UserDetail'));
const PageNotFound = lazy(() => import('#/common/components/PageNotFound'));

const withSuspense = Component => (
  <Suspense fallback={<ChatbotLoader />}>
    <Component />
  </Suspense>
);

const createPrivateRoute = Component => <PrivateRoute>{withSuspense(Component)}</PrivateRoute>;

export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path={ROUTES.LAYOUT} element={createPrivateRoute(Layout)}>
        <Route path={ROUTES.MEMBERSHIP} element={<Membership />} />
        <Route path={ROUTES.MY_CHATBOT} element={<MyChatBot />} />
        <Route path={ROUTES.CHATBOT_DETAIL} element={<ChatbotDetail />} />
        <Route path={ROUTES.CHATBOT_CREATE} element={<CreateChatbot />} />
        <Route path={ROUTES.STICKY_SIDE_BUTTON} element={<StickyButton />} />
        <Route path={ROUTES.STICKY_SIDE_BUTTON_CREATE} element={<CreateStickyButton />} />
        <Route path={ROUTES.STICKY_SIDE_BUTTON_DETAIL} element={<StickyButtonDetail />} />
        <Route path={ROUTES.DASHBOARD} element={<Dashboard />} />
        <Route path={ROUTES.USER_MANAGEMENT} element={<UserManagement />} />
        <Route path={ROUTES.USER_MANAGEMENT_DETAIL} element={<UserDetail />} />
      </Route>

      <Route path={ROUTES.LOGIN} element={<Login />} />
      <Route path={ROUTES.PAGE_NOT_FOUND} element={<PageNotFound />} />
      <Route path="*" element={<Navigate to={ROUTES.LOGIN} />} />
    </>
  ),
  { basename: '/app' }
);
