import { toast } from 'react-toastify';

export const getUserNameInitials = name => {
  if (!name) return '';

  const initials = name.match(/\b\w/g);
  if (!initials) return '';

  return initials?.join('')?.toUpperCase();
};

export const convertPathNameString = str => {
  return str
    .split('/')[1]
    .split('-')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const billingFormat = name => {
  if (!name) return '';

  let returnFormat = '';

  if (name === 'PER_MONTH') returnFormat = 'monthly';

  if (name === 'PER_YEAR') returnFormat = 'annually';

  return returnFormat;
};

export const formatNumber = (num, precision = 0) => {
  const map = [
    { suffix: 'T', threshold: 1e12 },
    { suffix: 'B', threshold: 1e9 },
    { suffix: 'M', threshold: 1e6 },
    { suffix: 'K', threshold: 1e3 },
    { suffix: '', threshold: 1 },
  ];

  const found = map.find(x => Math.abs(num) >= x.threshold);
  if (found) {
    const formatted = (num / found.threshold).toFixed(precision) + found.suffix;
    return formatted;
  }

  return num;
};

export const capitalizeText = text => {
  if (!text) return '';
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
};

export const removeUnderscoresWithSpaces = text => {
  return text.replace(/_/g, ' ').toLowerCase();
};

export const getFormDataFile = file => {
  if (!file) {
    console.error('No file provided for upload');
    return null;
  }

  const formData = new FormData();
  formData.append('file', file);
  return formData;
};

export const formatFileSize = bytes => {
  if (bytes < 1024 * 1024) {
    return Math.ceil(bytes / 1024) + ' KB';
  } else {
    return Math.ceil(bytes / (1024 * 1024)) + ' MB';
  }
};

export const getNextPage = lastPage => {
  if (lastPage?.data?.next) {
    const match = lastPage?.data?.next.match(/page=([0-9]+)/);
    const pageNumber = match ? match[1] : 1;
    return pageNumber;
  }
};

export const doInfiniteScroll = (event, hasNextPage, isFetching, fetchNextPage) => {
  const target = event.target;
  if (!target) return;

  const { scrollHeight, scrollTop, clientHeight } = target;

  if (hasNextPage && !isFetching && scrollHeight - scrollTop <= clientHeight + 10) {
    fetchNextPage();
  }
};

export const selectPickerOptions = list => {
  const { data } = list?.data || [];
  if (!data) return [];

  return data?.map(item => ({ value: item.id, label: capitalizeText(item.name) }));
};

export const truncateFileName = (fileName, maxLength = 20) => {
  const extIndex = fileName.lastIndexOf('.');
  if (extIndex === -1) return fileName;

  const namePart = fileName.substring(0, extIndex);
  const extPart = fileName.substring(extIndex);

  return namePart.length > maxLength ? namePart.substring(0, maxLength) + extPart : fileName;
};

export const copyCodeClipboard = code => {
  navigator.clipboard
    .writeText(code)
    .then(() => toast.success('Code copied successfully!'))
    .catch(error => console.error('Error copying text:', error));
};

export const resizeAndCompressImage = (file, options = {}) => {
  return new Promise((resolve, reject) => {
    if (!file || !file.type.startsWith('image/')) {
      reject(new Error('Invalid image file'));
      return;
    }

    const { maxWidth = 800, maxHeight = 800, maxSizeMB = 1, quality = 0.7, outputFormat = 'image/jpeg' } = options;

    const img = new Image();
    img.src = URL.createObjectURL(file);

    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      let { width, height } = img;

      // Scale down if necessary
      if (width > maxWidth || height > maxHeight) {
        const aspectRatio = width / height;
        if (width > height) {
          width = maxWidth;
          height = width / aspectRatio;
        } else {
          height = maxHeight;
          width = height * aspectRatio;
        }
      }

      canvas.width = width;
      canvas.height = height;
      ctx.drawImage(img, 0, 0, width, height);

      // Convert to Blob with compression
      canvas.toBlob(
        blob => {
          if (!blob) {
            reject(new Error('Failed to process image'));
            return;
          }

          // Check if compressed image is under maxSizeMB
          if (blob.size > maxSizeMB * 1024 * 1024) {
            reject(new Error(`Image exceeds ${maxSizeMB}MB limit`));
            return;
          }

          resolve(blob);
        },
        outputFormat,
        quality
      );
    };

    img.onerror = () => reject(new Error('Error loading image'));
  });
};

export const isURL = str => {
  try {
    new URL(str);
    return true;
  } catch {
    return false;
  }
};
