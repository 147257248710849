import { useRef, useState } from 'react';

import { Popover, PopoverContent } from '@carbon/react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { isEmpty } from 'lodash';
import { Bell } from 'lucide-react';
import { generatePath, useNavigate } from 'react-router-dom';

import { useAppContext } from '#/context';
import { NotFoundScreen } from '#/pages/my-chatbot';
import { ROUTES } from '#/routes';
import { apiEndpoints, queryKeys } from '#/services';
import { useGetInfiniteQuery } from '#/services/networkRequestService';
import { DETAIL_TABS, NOTIFICATION_TYPE } from '#/utils/constants';
import { doInfiniteScroll, getNextPage, removeUnderscoresWithSpaces } from '#/utils/helperFunctions';
import { Loader } from '.';
import { useOutsideClick } from '../hooks';
import styles from './Notifications.module.scss';

dayjs.extend(relativeTime);

function Notifications() {
  const notificationRef = useRef();
  const navigate = useNavigate();

  const {
    userDetails: { hasPendingNotifications, isStaff },
  } = useAppContext();

  const [showNotificationMenu, setShowNotificationMenu] = useState(false);

  const closeNotificationMenu = () => setShowNotificationMenu(false);

  useOutsideClick(notificationRef, closeNotificationMenu);

  const {
    data: notifications,
    isFetching: notificationsFetching,
    isPending: notificationsLoading,
    fetchNextPage,
    hasNextPage,
    refetch: notificationsRefetch,
  } = useGetInfiniteQuery({
    key: queryKeys.NOTIFICATIONS,
    url: apiEndpoints.NOTIFICATIONS,
    getNextPageParam: getNextPage,
    options: {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: false,
    },
  });

  const handleScroll = e => doInfiniteScroll(e, hasNextPage, notificationsFetching, fetchNextPage);

  const toggleNotificationMenu = () => {
    setShowNotificationMenu(!showNotificationMenu);
    if (!showNotificationMenu) {
      notificationsRefetch();
    }
  };

  const handleNavigate = (metadata, type) => () => {
    if (type === NOTIFICATION_TYPE.CHATBOT) {
      navigate(generatePath(ROUTES.CHATBOT_DETAIL, { id: metadata?.chatbotId }));
    } else if (type === NOTIFICATION_TYPE.LEAD) {
      if (metadata?.chatbotId) {
        navigate(`${generatePath(ROUTES.CHATBOT_DETAIL, { id: metadata?.chatbotId })}?tab=${DETAIL_TABS.LEADS}`);
      }
      if (metadata?.stickyButtonId) {
        navigate(`${generatePath(ROUTES.STICKY_SIDE_BUTTON_DETAIL, { id: metadata?.stickyButtonId })}`);
      }
    } else if (type === NOTIFICATION_TYPE.CONVERSATION) {
      navigate(
        `${generatePath(ROUTES.CHATBOT_DETAIL, { id: metadata?.chatbotId })}?tab=${DETAIL_TABS.CONVERSATION}&chat=${metadata?.chatThreadSessionId}&message=${metadata?.messageId}`
      );
    } else if (type === NOTIFICATION_TYPE.STICKY_BUTTON) {
      navigate(`${generatePath(ROUTES.STICKY_SIDE_BUTTON_DETAIL, { id: metadata?.stickyButtonId })}`);
    }
    closeNotificationMenu();
  };

  return isStaff ? null : (
    <Popover ref={notificationRef} open={showNotificationMenu} align="bottom-start" autoAlign={true} caret={false}>
      <button
        onClick={toggleNotificationMenu}
        className={`${styles.notification} ${hasPendingNotifications ? styles.pendingNotification : ''} header-action-btn`}
      >
        <Bell />
      </button>
      <PopoverContent className="notification-content">
        <div className="topHead">
          <p>Notifications</p>
        </div>
        <div className="content-body" onScroll={handleScroll}>
          {notificationsLoading ? (
            <div className="notification-loader">
              <Loader />
            </div>
          ) : isEmpty(notifications?.pages[0]?.data?.results) ? (
            <NotFoundScreen title="Notification" />
          ) : (
            notifications?.pages
              ?.flatMap(page => page?.data?.results)
              ?.map(notification => {
                const { id, created, message, isRead, metadata, type } = notification || {};
                return (
                  <div className="notification" key={id} onClick={handleNavigate(metadata, type)}>
                    <div className={`heading ${isRead ? '' : 'unread'}`}>
                      <h3>{metadata?.chatbotName || metadata?.stickyButtonName}</h3>
                      {!!metadata?.reaction && (
                        <span className="icon">
                          <img src={metadata?.reactionEmoji} />
                        </span>
                      )}
                    </div>
                    <div className="reference">
                      <span className="time">{dayjs(created).fromNow()}</span>
                      <span className="sender">{removeUnderscoresWithSpaces(type)}</span>
                    </div>
                    <div className="description">
                      <p>{message}</p>
                    </div>
                  </div>
                );
              })
          )}
        </div>
      </PopoverContent>
    </Popover>
  );
}

export default Notifications;
