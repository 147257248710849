import { Pagination } from '@carbon/react';
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import { Edit2, Eye } from 'lucide-react';
import { generatePath, useNavigate } from 'react-router-dom';

import ChatbotIcon from '#/assets/svgs/ChatbotIcon.svg';
import { ROUTES } from '#/routes';
import { DATE_FORMAT, DETAIL_TABS, INTERACTIONS, PAGE_SIZES } from '#/utils/constants';
import { setLocalStorageItem } from '#/utils/localStorage';

dayjs.extend(relativeTime);
dayjs.extend(utc);

const columnHelper = createColumnHelper();

const columns = [
  columnHelper.accessor('name', {
    header: 'Name',
    size: 340,
    cell: info => {
      const { setting: { chatbotImage, botName, supportEmail } = {} } = info?.row?.original || {};
      return (
        <div className="name-block">
          <span className="avatar">
            {chatbotImage?.image ? <img src={chatbotImage?.image} alt="Logo" /> : <ChatbotIcon />}
          </span>
          <span className="title">
            <h5>{botName}</h5>
            <p>{supportEmail}</p>
          </span>
        </div>
      );
    },
  }),
  columnHelper.accessor('created', {
    header: 'Created On',
    size: 130,
    cell: info => dayjs.utc(info.getValue()).format(DATE_FORMAT.CHATBOT_DATE),
  }),
  columnHelper.accessor('lastSeen', {
    header: 'Last Message',
    size: 128,
    cell: info => (info.getValue() ? dayjs(info.getValue()).fromNow() : '-'),
  }),
  columnHelper.accessor('totalContentLength', {
    header: 'Characters',
    size: 112,
  }),
  columnHelper.accessor('conversationCount', {
    header: 'Conversations',
    size: 130,
  }),
  columnHelper.accessor('status', {
    header: 'Status',
    size: 120,
    cell: info => (
      <div className={`${info.getValue() === 'ACTIVE' ? 'active' : 'inactive'} status`}>
        {info.getValue() === 'ACTIVE' ? 'Active' : 'Inactive'}
      </div>
    ),
  }),

  columnHelper.display({
    id: 'actions',
    header: 'Action',
    size: 116,
  }),
];

function ChatbotTable({ data, page, pageSize, onPageChange, count }) {
  const navigate = useNavigate();

  const handleChatbotPreviewBtn = id => () => {
    navigate(`${generatePath(ROUTES.CHATBOT_DETAIL, { id })}?tab=${DETAIL_TABS.CONVERSATION}`);
  };

  const handleChatbotEditBtn = id => e => {
    e.stopPropagation();
    navigate(`${generatePath(ROUTES.CHATBOT_CREATE, { id })}?name=${INTERACTIONS[0]?.value}`);
    setLocalStorageItem('isEdit', true);
  };

  const table = useReactTable({
    data,
    columns: columns.map(col =>
      col.id === 'actions'
        ? {
          ...col,
          cell: ({ row }) => (
            <div className="actions">
              <button className="btn btn-transparent" onClick={handleChatbotPreviewBtn(row.original.id)}>
                <Eye />
              </button>
              <button className="btn btn-transparent" onClick={handleChatbotEditBtn(row.original.id)}>
                <Edit2 />
              </button>
            </div>
          ),
        }
        : col
    ),
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <table className="table">
      <thead className="table-head">
        {table.getHeaderGroups().map(headerGroup => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map(header => (
              <th key={header.id} style={{ width: header.column.getSize() }}>
                {flexRender(header.column.columnDef.header, header.getContext())}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody className="table-body">
        {table.getRowModel().rows.map(row => (
          <tr key={row.original.id} onClick={handleChatbotPreviewBtn(row.original.id)}>
            {row.getVisibleCells().map(cell => (
              <td key={cell.id} style={{ width: cell.column.getSize() }}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
      <tfoot className="table-footer">
        <tr>
          <td colSpan={columns.length}>
            <Pagination
              itemsPerPageText="Items per page:"
              page={page}
              pageSize={pageSize}
              pageSizes={PAGE_SIZES}
              size="lg"
              totalItems={count}
              onChange={onPageChange}
            />
          </td>
        </tr>
      </tfoot>
    </table>
  );
}

export default ChatbotTable;
