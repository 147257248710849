import {
  BotMessageSquare,
  Briefcase,
  Brush,
  Hexagon,
  House,
  Info,
  Layers3,
  Link,
  List,
  Users,
  Zap,
} from 'lucide-react';

import { ROUTES } from '#/routes';

export const ACCESS_TOKEN = 'accessToken';

export const COLORS = {
  WHITE: '#ffffff',
  GRAY100: '#D0D5DD',
  SECONDARY: '#5532FA',
};

export const SIDEBAR_ITEMS = [
  {
    icon: House,
    heading: 'Dashboard',
    route: ROUTES.DASHBOARD,
  },
  {
    icon: Users,
    heading: 'User Management',
    route: ROUTES.USER_MANAGEMENT,
  },
  {
    icon: BotMessageSquare,
    heading: 'My Chatbots',
    route: ROUTES.MY_CHATBOT,
  },
  {
    icon: Hexagon,
    heading: 'Sticky Side Button',
    route: ROUTES.STICKY_SIDE_BUTTON,
  },
  {
    icon: Briefcase,
    heading: 'Membership',
    route: ROUTES.MEMBERSHIP,
  },
];

export const PRICING_PLAN_FEATURES = {
  CHARACTERS: 'CHARACTERS',
  MESSAGES: 'MESSAGES',
  CHATBOT: 'CHATBOT',
};

export const NETWORK_ERROR = 'Network Error';
export const ERROR_STATUS_500 = 5;
export const ERROR_STATUS_401 = 401;
export const ERROR_STATUS_403 = 403;
export const ERROR_STATUS_404 = 404;
export const ERROR_MESSAGE = 'Something went wrong!';

export const FILES = 'files';
export const WEBSITES = 'websites';

export const STATUSES = {
  COMPLETED: 'COMPLETED',
  FAILURE: 'FAILURE',
};

export const REFETCH_INTERVAL = 5000;

export const DATE_FORMAT = {
  CHATBOT_DATE: 'DD MMM YYYY',
  REQUESTED_DATE: 'YYYY-MM-DD',
  RANGE_DATE: 'DD/MM/YYYY',
};

export const TOTAL_ITEMS_PER_PAGE = 10;

export const PAGE_SIZES = [5, 10, 20, 30, 40, 50];

export const INTERACTIONS = [
  {
    icon: <Info />,
    title: 'Basic Information',
    value: 'basic-info',
  },
  {
    icon: <Brush />,
    title: 'Appearance',
    value: 'appearance',
  },
  {
    icon: <Zap />,
    title: 'Lead Magnet',
    value: 'lead-magnet',
  },
  {
    icon: <List />,
    title: 'Call to Action',
    value: 'call-to-action',
  },
  {
    icon: <Layers3 />,
    title: 'Manage Data',
    value: 'manage-data',
  },
  {
    icon: <Link />,
    title: 'Embed Chatbot',
    value: 'embed-chatbot',
  },
];

export const STICKY_BTN_INTERACTIONS = [
  {
    icon: <Info />,
    title: 'Basic Information',
    value: 'basic-info',
  },
  {
    icon: <Link />,
    title: 'Embed Chatbot',
    value: 'embed-chatbot',
  },
];

export const INVALID_EMAIL_ADDRESS = 'Invalid email address';

export const LEAD_FIELD_CHOICES_OPTION = [
  { label: 'Text', value: 'TEXT' },
  { label: 'Number', value: 'NUMBER' },
  { label: 'Email', value: 'EMAIL' },
  { label: 'Upload', value: 'UPLOAD' },
];

export const DETAIL_TABS = {
  CONVERSATION: 'conversation',
  LEADS: 'leads',
  PREVIEW: 'preview',
};

export const defaultField = {
  label: '',
  type: 'TEXT',
  placeholder: 'Enter label',
  isRequired: false,
  fieldItems: [],
  sortingOrder: 1,
};

export const defaultLeadForm = {
  name: 'Book a free survey visit',
  fields: [defaultField],
};

export const STICKY_SIDE_BUTTON_CREATE = '/sticky-side-button/create';
export const STICKY_SIDE_BUTTON_DETAIL = '/sticky-side-button/detail';
export const CREATE_CHATBOT = '/my-chatbots/create-chatbot';

export const STICKY_BTN_TABS = {
  LEADS: 'leads',
  PREVIEW: 'preview',
};

export const VALID_IMAGE_TYPE = '.jpeg, .jpg, .png';

export const ROLE = {
  USER: 'user',
  ASSISTANT: 'assistant',
};

export const MAX_FILE_SIZE = 10 * 1024 * 1024;

export const NOTIFICATION_TYPE = {
  CHATBOT: 'CHATBOT',
  LEAD: 'LEAD',
  CONVERSATION: 'CONVERSATION',
  STICKY_BUTTON: 'STICKY_BUTTON',
};

export const CHARACTERS_MAX_LENGTH = 24;

export const NOTIFICATION_REFETCH_INTERVAL = 60000;

export const USER_DETAIL_TABS = {
  CHAT_BOTS: 'chatbots',
  STICKY_BUTTONS: 'sticky-buttons',
  SUBSCRIPTION_HISTORY: 'subscription-history',
};

export const USER_CHATBOT_TABS = {
  DETAILS: 'details',
  CONVERSATION: 'conversation',
  LEADS: 'leads',
};
