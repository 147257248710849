import { GoogleOAuthProvider } from '@react-oauth/google';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import CacheBuster from 'react-cache-buster';
import { RouterProvider } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { AppContextProvider } from '#/context';
import { router } from '#/routes';
import { useSentry, useSetupAxios } from '#/services';
import { version } from '../package.json';

function App() {
  const queryClient = new QueryClient();

  const isCacheBusterEnable = !!Number(import.meta.env.VITE_CACHE_BUSTER);
  const GOOGLE_OAUTH_CLIENT_ID = import.meta.env.VITE_CLIENT_ID;

  useSetupAxios();
  useSentry();

  return (
    <GoogleOAuthProvider clientId={GOOGLE_OAUTH_CLIENT_ID}>
      <QueryClientProvider client={queryClient}>
        <AppContextProvider>
          <CacheBuster currentVersion={version} isEnabled={isCacheBusterEnable} metaFileDirectory={'.'}>
            <RouterProvider router={router} />
          </CacheBuster>
        </AppContextProvider>
        <ToastContainer />
        <ReactQueryDevtools />
      </QueryClientProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
